import { Box, Button, Center, Flex, Image, Input, Radio, RadioGroup, Select, Stack } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react'
import Header from '../components/Header';
import { Editor } from '@toast-ui/react-editor';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import '@toast-ui/editor/dist/toastui-editor.css';
import '@toast-ui/editor/dist/i18n/ko-kr';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import axios from 'axios';
import { useMutation } from 'react-query';
import { uploadImage, uploadPost } from '../api/api';
import { useNavigate } from 'react-router-dom';

const CreateForm = () => {
    const editorRef = useRef();
    const selectRef = useRef();
    const [postTitle, setPostTitle] = useState('');
    const fileUploadRef = useRef();
    const [mainImageId, setMainImageId] = useState('');
    const navigate = useNavigate();


    const { mutate, isLoading } = useMutation({
        mutationFn: uploadPost,
        onSuccess(data) {
            console.log(data);
            alert('등록 성공');
            navigate(`/notice/${data.results}`)
        },
        onError(error) {
            alert('업로드 중 에러가 발생 했습니다.');
        }
    })

    useEffect(() => {
        console.log(editorRef)
    }, [])

    const onSubmit = () => {
        const content = editorRef.current?.getInstance().getHTML();

        const body = {
            title: postTitle,
            content,
            image: `${process.env.REACT_APP_SERVER_URL}/assets/${!!mainImageId ? mainImageId : "851fa9a4-bfae-4a3e-986c-500ab3086674"}`,
            post_type: selectRef.current.value
        }

        mutate(body);
    }

    return (
        <Flex width={'100%'} flexDirection={'column'}>
            <Header />
            <Box flex={1} padding={5}>
                <Box mb={5}>
                    <Box width={300} height={300} cursor={'pointer'} onClick={() => fileUploadRef.current.click()} backgroundColor={'gray'} padding={5} borderRadius={5}>
                        <Image
                            src={`${process.env.REACT_APP_SERVER_URL}/assets/${!!mainImageId ? mainImageId : "851fa9a4-bfae-4a3e-986c-500ab3086674"}`}
                            width={'100%'}
                            height={'100%'}
                            borderRadius={5}
                        />
                    </Box>
                    <Input type="file"
                        accept='.png, .jpg, .jpeg'
                        onChange={async (e) => {
                            console.log(e.target.files);

                            const { data } = await uploadImage(e.target.files[0]);

                            console.log(data);

                            setMainImageId(data.id);
                        }}
                        ref={fileUploadRef}
                        style={{ display: "none" }} />
                </Box>
                <Input
                    placeholder='글의 제목을 입력해주세요'
                    marginBottom={5}
                    value={postTitle}
                    onChange={e => setPostTitle(e.target.value)}
                />
                <Box width={'20%'} marginBottom={5}>
                    <Select placeholder='-' defaultValue={'public_post'} ref={selectRef}>
                        <option value='public_post'>공지사항</option>
                        <option value='event'>이벤트</option>
                    </Select>
                </Box>
                <Editor
                    height='800px'
                    initialEditType='wysiwyg'
                    usageStatistics={false}
                    hideModeSwitch={true}
                    initialValue={''}
                    plugins={[colorSyntax]}
                    language="ko-KR"
                    ref={editorRef}
                    hooks={{
                        async addImageBlobHook(blob, callback) {
                            try {
                                const formData = new FormData();
                                formData.append('files', blob);

                                const { data: postData } = await axios.post(`${process.env.REACT_APP_SERVER_URL}/files`, formData, {
                                    headers: {
                                        Authorization: `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
                                    },
                                });

                                callback(`${process.env.REACT_APP_SERVER_URL}/assets/${postData.data.id}`, `image`);
                            } catch (error) {
                                alert('이미지 업로드 중 에러가 발생했습니다.')
                            }
                        }
                    }}
                />
            </Box >
            <Flex padding={5} justifyContent={'space-between'}>
                <Button width={'49%'} colorScheme='red' height={'40px'}>
                    취소
                </Button>
                <Button width={'49%'} colorScheme='whatsapp' height={'40px'} onClick={onSubmit}>
                    작성
                </Button>
            </Flex>
        </Flex >
    )
}

export default CreateForm