import { Button, Center, Flex, Image, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import Header from '../components/Header';
import { useMutation } from 'react-query';
import { eventAirdrop } from '../api/api';

const DrawnPage = () => {

    const [user, setUser] = useState(null);
    const { mutate } = useMutation({
        mutationFn: eventAirdrop,
        onSuccess(data) {
            window.ReactNativeWebView?.postMessage(JSON.stringify({ type: 'claim', data }));
        },
        onError(error) {
            alert(JSON.stringify(error))
        }
    })

    useEffect(() => {
        if (window.ReactNativeWebView) {
            document.addEventListener('message', (event) => {
                if (!user) {
                    const parseData = JSON.parse(event.data);
                    setUser(parseData);
                }
            })
            window.addEventListener('message', (event) => {
                if (!user) {
                    const parseData = JSON.parse(event.data);
                    setUser(parseData);
                }
            })
        } else {
            setUser({ email: '123', session: '456', user_index: '789' })
        }
    }, [])

    const postMessage = (type, data) => {
        if (window.ReactNativeWebView) {
            mutate({ email: user?.email, session: user?.session, user_index: user?.user_index });
            // mutate({ email: 'test1@test.com', session: 'd143a8a7-078d-4095-b72c-69c2c10a919e', user_index: 'f93ebb6e-edaf-45da-9458-e7e80a1aed9b' });
        } else {
            console.log('Hello!');
            mutate({ email: user?.email, session: user?.session, user_index: user?.user_index });
        }
    };


    return (
        <Center flexDirection={'column'} width={'100%'} minHeight={'100vh'} height={'100vh'} background={"linear-gradient(to bottom, #212121 0%, #090909 100%)"}>

            <Flex width={'100%'} height={'70%'} padding={5} flexDirection={'column'}>
                <Flex flex={1} flexDirection={'column'}>
                    <Center flexDirection={'column'}>
                        <Image src={require('../assets/main_logo.png')} width={220} />
                        <Image src={require('../assets/eml_banner.png')} />
                    </Center>
                    <Center flexDirection={'column'}>
                        {/* <Text fontFamily={'Noto Sans KR'} fontSize={10} color={'#fff'} marginBottom={5} fontWeight={'bold'}>
                            {user?.email}
                        </Text> */}
                        <Text
                            color={'#fff'}
                            fontSize={23}
                            fontWeight={'bold'}
                        >
                            {user?.username}
                        </Text>
                    </Center>
                    <Center>
                        <Text color={'#fff'}>did you participate in the event?</Text>
                    </Center>
                </Flex>
                <Center width={'100%'}>
                    <Button
                        colorScheme='whatsapp'
                        width={'80%'}
                        height={'50px'}
                        fontFamily={'Noto Sans KR'}
                        onClick={() => { postMessage('claim', user?.email) }}>
                        Claim
                    </Button>
                </Center>
            </Flex>
        </Center>
    )
}

export default DrawnPage