import React, { useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2'
import { Chart as ChartJs } from "chart.js/auto";
import { useParams } from 'react-router-dom';
import { QueryClient, useQueries, useQuery } from 'react-query';
import { getChartData, getPointChartData } from '../api/api';
import loadingLottie from "../assets/loading_Lotte.json";
import { Box, Button, Center, Flex, Image, Text } from '@chakra-ui/react';
import Lottie from 'lottie-react';
import zoomPlugin from 'chartjs-plugin-zoom';
import moment from 'moment';
import { motion } from 'framer-motion';
import SettingIcon from '../assets/icon/SettingIcon';
import CloseIcon from '../assets/icon/CloseIcon';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false
        }
    }
});

const Chart = () => {

    const params = useParams();
    const [graphData, setGraphData] = useState({
        date_label: null,
        date_price: null,
        time_label: null,
        time_price: null
    });
    const [pointData, setPointData] = useState({
        date_label: null,
        date_price: null,
        time_label: null,
        time_price: null
    });
    const [language, setLanguage] = useState('');
    const [symbol, setSymbol] = useState('');
    const [settingModal, setSettingModal] = useState(false);
    const [tokenImage, setTokenImage] = useState('');
    const [session, setSession] = useState('');
    const [userIndex, setUserIndex] = useState('');
    const [graphType, setGraphType] = useState('all');
    const [graphTime, setGraphTime] = useState('day');
    const [graphDummy, setGraphDummy] = useState({
        label: [],
        data1: [],
        data2: []
    })

    // const { isLoading } = useQuery(['chart_data', params.token_index], () => getChartData(params.token_index, params.type),
    //     {
    //         enabled: !!params.token_index && !!params.point_index,
    //         onSuccess(data) {
    //             const parsingData = data.results?.duplicateDates;

    //             let date = [];
    //             let chartValue = [];

    //             Object.entries(parsingData).forEach(([key, value]) => {
    //                 date.push(moment(key, 'YYYY-MM-DD').format('DD MMM'));
    //                 // date.push(key);
    //                 chartValue.push(Number.isInteger(value.usdAvgPrice) ? value.usdAvgPrice : value.usdAvgPrice.toFixed(2))
    //             })

    //             chartValue.sort((a, b) => {
    //                 return b.origin_date - a.origin_date;
    //             })

    //             setGraphData([date, chartValue]);
    //         }
    //     }
    // )
    const animation = {
        tension: {
            from: 0,
            to: .5,
            duration: 1000,
            easing: 'linear',
        }
    };

    const { isLoading: tokenLoading, isRefetching: tokenRefetcing, refetch: tokenRefetch, isError: tokenError } = useQuery('chart_data', () => getChartData(params.token_index, graphType, session, userIndex), {
        enabled: params.token_index !== undefined && !!session && !!userIndex,
        onSuccess(data) {
            console.log(data, '토큰')
            const { results, RETCODE } = data;
            setSymbol(results.symbol);
            setTokenImage(results.image);
            // setGraphData([results.date[0], results.date[1]]);
            setGraphData({
                date_label: results.date[0],
                date_price: results.date[1],
                time_label: results.time[0],
                time_price: results.time[1]
            });
        },
        onError(err) {
            console.log(err)
            alert(JSON.stringify(err));
        }
    })
    const { isLoading: pointLoading, isRefetching: pointRefetcing, refetch: pointRefetch, isError: pointError } = useQuery('chart_data', () => getPointChartData(params.point_index, graphType, session, userIndex), {
        enabled: params.token_index !== undefined && !!session && !!userIndex,
        onSuccess(data) {
            console.log(data, '토큰')
            const { results, RETCODE } = data;

            setPointData({
                date_label: results.date[0],
                date_price: results.date[1],
                time_label: results.time[0],
                time_price: results.time[1]
            });
        },
        onError(err) {
            alert(JSON.stringify(err));
        }
    })

    const loading = tokenLoading || tokenRefetcing || pointRefetcing || pointLoading || !graphData || !pointData;
    const error = tokenError || pointError;

    useEffect(() => {
        console.log(error, '에러')
    }, [error])

    useEffect(() => {
        ChartJs.defaults.color = '#fff'
    }, [])


    useEffect(() => {
        ChartJs.register(zoomPlugin);
        if (window.ReactNativeWebView) {
            window.addEventListener('message', (event) => {
                if (!language || !session || !userIndex) {
                    const parseData = JSON.parse(event.data);
                    setSession(parseData.session)
                    console.log(parseData.session);
                    setLanguage(parseData.lang);
                    setUserIndex(parseData.user_index);
                }
            })

            document.addEventListener('message', (event) => {
                if (!language || !session || !userIndex) {
                    const parseData = JSON.parse(event.data);
                    setSession(parseData.session)
                    console.log(parseData.session);
                    setLanguage(parseData.lang);
                    setUserIndex(parseData.user_index);
                }
            })
        }

    }, [])

    if (error) {
        return (
            <></>
        )
    }

    if (loading) {
        return (
            <Center height={'100%'} width={'100%'}>
                <Lottie animationData={loadingLottie} />
            </Center>
        )
    }


    const renderLabel = () => {
        if (graphTime === 'day') {
            if (graphData.date_label?.length >= pointData.date_label?.length) {
                return graphData.date_label
            } else {
                return pointData.date_label
            }
        } else {
            if (graphData.time_label?.length >= pointData.time_label?.length) {
                return graphData.time_label
            } else {
                return pointData.time_label
            }
        }
    }

    // const body = {
    //     tokens_index: coinData.index,
    //     period: '1d',
    // };
    // try {
    //     const data = await axios.post(`${SERVER_URL}/endpoints/tse_price_info`, body);
    //     const pointData = await axios.post(`${SERVER_URL}/endpoints/trade_price_info_point`, {
    //         points_index: coinData?.index,
    //     });
    //     if (coinData.hasOwnProperty('is_token')) {
    //         if (data) {
    //             return data;
    //         }
    //     }
    //     if (coinData.hasOwnProperty('is_point')) {
    //         return pointData;
    //     }
    // } catch (error) {
    //     console.log('🚀 ~ file: MarketDetail.js:121 ~ getTokenfilterData ~ error:', error);
    // } finally {
    //     setTokenDataLoading(false);
    // }

    return (
        <Box style={{ width: "100%", height: '220px', backgroundColor: "red" }}>
            {
                settingModal &&
                <Box width={'100%'} height={'220px'} position={'fixed'} top={0} zIndex={99}>
                    <motion.div
                        initial={{ opacity: 0, width: "100%", height: "100%" }}
                        animate={{ opacity: 1, backgroundColor: 'rgba(0,0,0,0.9)' }}
                        style={{ display: "flex", flexDirection: 'column', padding: 10 }}
                    >
                        <Flex flex={1} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                            <Center height={5}>
                                <Text color={'#fff'} fontWeight={'bold'} fontSize={14}>
                                    Graph Setting
                                </Text>
                            </Center>
                            <Button position={'absolute'} top={3} right={3} height={7} padding={0} zIndex={9999} backgroundColor={'#fff'} onClick={() => setSettingModal(false)}>
                                <CloseIcon />
                            </Button>
                            <Flex flex={1} flexDirection={'column'} justifyContent={'center'} width={'100%'} alignItems={'center'}>
                                <Box width={'70%'} marginBottom={1}>
                                    <Text color={'#fff'} fontSize={12} fontWeight={'bold'}>Period</Text>
                                </Box>
                                <Flex width={'70%'} justifyContent={'space-between'}>
                                    <Button width={'45%'} fontSize={13} height={30} colorScheme={graphTime === 'day' ? 'telegram' : 'gray'} onClick={() => setGraphTime('day')}>
                                        day
                                    </Button>
                                    <Button width={'45%'} fontSize={13} height={30} colorScheme={graphTime === 'time' ? 'telegram' : 'gray'} onClick={() => setGraphTime('time')}>
                                        time
                                    </Button>
                                </Flex>
                                <Box width={'70%'} marginTop={3} marginBottom={1}>
                                    <Text color={'#fff'} fontSize={12} fontWeight={'bold'}>Period</Text>
                                </Box>
                                <Flex width={'70%'} justifyContent={'space-between'}>
                                    <Button width={'25%'} fontSize={13} height={30} colorScheme={graphType === 'all' ? 'telegram' : 'gray'} onClick={() => setGraphType('all')}>
                                        All
                                    </Button>
                                    <Button width={'25%'} fontSize={13} height={30} colorScheme={graphType === 'official_currency' ? 'telegram' : 'gray'} onClick={() => setGraphType('official_currency')}>
                                        Cash
                                    </Button>
                                    <Button width={'25%'} fontSize={13} height={30} colorScheme={graphType === 'crypto_currency' ? 'telegram' : 'gray'} onClick={() => setGraphType('crypto_currency')}>
                                        Crypto
                                    </Button>
                                </Flex>
                            </Flex>
                            <Button width={'70%'} colorScheme='whatsapp' height={8} fontSize={13} onClick={() => {
                                setSettingModal(false);
                                tokenRefetch();
                                pointRefetch();
                            }}>
                                Setting
                            </Button>

                        </Flex>
                    </motion.div>
                </Box>
            }
            <Box position={'absolute'} left={3} top={3}>
                <Image
                    src={tokenImage}
                    style={{ width: "25px", height: "25px" }}
                />
            </Box>
            {
                !settingModal &&
                <Box position={'absolute'} right={3} top={3} zIndex={999}>
                    <Button colorScheme='gray' margin={0} padding={0} width={'20px'} height={'28px'} onClick={() => setSettingModal(true)}>
                        <SettingIcon />
                    </Button>
                </Box>
            }
            {
                graphData.date_label?.length === 0 &&
                <Center width={'100%'} height={220} backgroundColor={'rgba(0,0,0,0.9)'} position={'absolute'}>
                    <Text color={'#fff'} fontWeight={'bold'}>
                        {
                            language === 'ko'
                                ? '현재 집계중 입니다'
                                : 'currently aggregating the data'
                        }
                    </Text>
                </Center>
            }
            <Line
                datasetIdKey='id'
                style={{ width: "100%", height: "100%", backgroundColor: "#212121" }}
                data={
                    {
                        // labels: graphData[0].reverse(),
                        labels: renderLabel(),
                        datasets: [
                            {
                                id: 1,
                                label: 'Token',
                                data: graphTime === 'day' ? graphData.date_price : graphData.time_label,
                                fill: false,
                                borderColor: "#4B89DC",
                                borderWidth: 1,
                                pointBorderWidth: 0,
                            },
                            {
                                id: 2,
                                label: "Point",
                                fill: false,
                                borderColor: "#DB4455",
                                borderWidth: 1,
                                data: graphTime === 'day' ? pointData.date_price : pointData.time_price
                            }
                        ],
                    }
                }
                options={{
                    animation,
                    pointBorderWidth: 0,
                    responsive: true,
                    tension: .1,
                    color: "#fff",
                    interaction: {
                        intersect: false
                    },
                    scales: {
                        y: {
                            min: 0,
                        }
                    },
                    plugins: {
                        legend: {
                            labels: {
                                font: {
                                    size: 12,
                                    weight: "bold"
                                },
                                color: "#fff",
                            },
                        },
                        title: {
                            display: true,
                            text: symbol,
                            color: "#fff",
                            font: {
                                size: 13
                            }
                        }
                        ,
                        zoom: {
                            limits: {
                                y: { min: 0, max: ([133, 442, 213, 553, 90, 300, 700, 32, 41].sort((a, b) => b - a)[0] + 90) },
                                y2: { min: 0, max: ([133, 442, 213, 553, 90, 300, 700, 32, 41].sort((a, b) => b - a)[0] + 90) },
                                x: { min: 0, max: ([133, 442, 213, 553, 90, 300, 700, 32, 41].sort((a, b) => b - a)[0] + 90) },
                            },
                            zoom: {
                                wheel: {
                                    speed: 0.001,
                                    enabled: true
                                },
                                pan: {
                                    enabled: true
                                },
                                limits: {
                                    y: { min: 0, max: 100 },
                                    y2: { min: -5, max: 5 },
                                },
                                pinch: {
                                    enabled: true,
                                    speed: 0.1
                                },
                                mode: 'y',
                            }
                        }
                    }
                }
                }
            />
        </Box>
    )
}

export default Chart