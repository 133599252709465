import { Box, Button, Center, Container, Flex, FormControl, FormHelperText, FormLabel, Image, Input, Text, VStack, useMediaQuery } from '@chakra-ui/react'
import React, { useEffect } from 'react'

const EventPage = () => {
    const [web] = useMediaQuery('(min-width: 1070px)');

    useEffect(() => {
        if (window.ReactNativeWebView) {
        }
    }, [])

    const postMessage = (type, data) => {
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView?.postMessage(JSON.stringify({ type, data }));
        } else {
            console.log('Hello!')
        }
    };

    return (
        <Flex width={'100%'} minHeight={'100vh'} height={'100vh'} background={"linear-gradient(to bottom, #212121 0%, #090909 100%)"} justifyContent={'center'} alignItems={'center'}>
            <Flex background={'linear-gradient(49deg, rgb(22, 135, 237), rgb(20, 55, 90))'} flexDirection={'column'} width={web ? '50%' : '90%'} minHeight={'70%'} height={'70%'} borderRadius={'xl'} padding={5}>
                <Center width={'100%'} height={'25%'}>
                    <Flex width={'100%'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                        <Image src={require('../assets/main_logo.png')} width={web ? '250px' : '130px'} />
                        <Text color={'#fff'} fontWeight={'900'} fontSize={web ? 30 : 20} marginTop={5}>Presenting an event</Text>
                    </Flex>
                </Center>
                <Center flex={1} flexDirection={'column'}>
                    <FormControl display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                        <Box width={web ? '80%' : '90%'}>
                            <FormLabel color={'#fff'} fontFamily={'Noto Sans KR'} fontWeight={'900'}>Email address</FormLabel>
                            <Input type='email' color={'#fff'} fontSize={13} _focus={{ borderColor: 'green' }} fontWeight={'900'} />
                            <FormHelperText color={'#fff'}>We'll never share your email.</FormHelperText>
                        </Box>
                    </FormControl>
                    <FormControl display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                        <Box width={web ? '80%' : '90%'} marginTop={15}>
                            <FormLabel color={'#fff'} fontFamily={'Noto Sans KR'} fontWeight={'900'}>Coupon Number</FormLabel>
                            <Input color={'#fff'} fontSize={13} />
                            <FormHelperText color={'#fff'}>We'll never share your Coupon Number.</FormHelperText>
                        </Box>
                    </FormControl>
                </Center>
                <Center width={'100%'}>
                    <Button width={web ? '80%' : '90%'} colorScheme='whatsapp' onClick={() => postMessage('success', '성공!')}>
                        Get Points!
                    </Button>
                </Center>
            </Flex>
        </Flex>
    )
}

export default EventPage