import { Flex, Image } from '@chakra-ui/react'
import React from 'react'

const Header = () => {
    return (
        <Flex width={'100%'} background={"linear-gradient(35deg, #4f1bc5, #7b17c5)"} padding={5}>
            <Image
                src={require('../assets/main_logo.png')}
                width={120}
            />
        </Flex>
    )
}

export default Header