import React, { useEffect } from 'react'
import { TransakConfig, Transak } from '@transak/transak-sdk';


const Transac = () => {

    const transakConfig = {
        apiKey: 'dd3f6156-a48d-4e51-b556-b50dfef60134', // (Required)
        environment: Transak.ENVIRONMENTS.STAGING
    };

    let transak = new Transak(transakConfig);


    // To get all SDK events
    Transak.on('*', (data) => {
        console.log(data);
    });

    // This will trigger when the user closed the widget
    Transak.on(Transak.EVENTS.TRANSAK_WIDGET_CLOSE, () => {
        console.log('Transak SDK closed!');
    });

    /*
    * This will trigger when the user has confirmed the order
    * This doesn't guarantee that payment has completed in all scenarios
    * If you want to close/navigate away, use the TRANSAK_ORDER_SUCCESSFUL event
    */
    Transak.on(Transak.EVENTS.TRANSAK_ORDER_CREATED, (orderData) => {
        console.log(orderData);
    });

    /*
    * This will trigger when the user marks payment is made
    * You can close/navigate away at this event
    */
    Transak.on(Transak.EVENTS.TRANSAK_ORDER_SUCCESSFUL, (orderData) => {
        console.log(orderData);
        transak.close();
    });


    useEffect(() => {
        transak.init();
    }, [])

    return (
        <div>T</div>
    )
}

export default Transac