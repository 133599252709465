import { Box, Center, Flex, Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs, Text, VStack } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import noticeMockData from '../mock/noticeList.json'
import NoticeList from '../components/NoticeList'
import Header from '../components/Header'
import { Viewer } from '@toast-ui/react-editor'
import '@toast-ui/editor/dist/toastui-editor.css';
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { postDetail } from '../api/api'
import loadingLottie from "../assets/loading_Lotte.json";
import Lottie from 'lottie-react'

const MainNotice = () => {
    const params = useParams();
    const { data, isLoading } = useQuery(['postDetail', params.id], () => postDetail(params.id), {
        onError(error) {
            console.log(error);
            alert('글을 찾을 수 없습니다.')
        },
        onSuccess(data) {
            console.log(data);
        },
        enabled: !!params.id
    })


    useEffect(() => {
        console.log(params.id, 'id');
    }, [])

    if (isLoading) {
        return (
            <Center height={'100%'} width={'100%'}>
                <Lottie animationData={loadingLottie} />
            </Center>
        )
    }

    return (
        <Flex flexDirection={'column'} justifyContent={'center'} alignItems={'center'} width={'100%'} height={'100%'}>
            <Header />
            <Flex width={'100%'} height={'100%'} className='viewer_wrap' padding={5}>
                <Viewer
                    initialValue={data?.results[0]?.content}
                />
            </Flex>
        </Flex>
    )
}

export default MainNotice